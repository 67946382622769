<template>
  <v-data-table
    v-bind="$attrs"
    must-sort
    fixed-header
    show-expand
    :loading="loading"
    :headers="headers"
    :items="logs"
    no-data-text="No activity"
    item-key="id"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 10, 25, 50, 100 ],
      showFirstLastPage: true,
    }"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col
          md="2"
        >
          <client-filter
            key="client-filter"
            :value.sync="options.filter.client"
          />
        </v-col>
        <!-- <v-col md="2">
          <table-filter
            :value.sync="options.filter.subject"
            :choices="[
              { text:'Lab Result', value: 'App\\Lab Result' },
              { text:'Prescription', value: 'App\\Prescription' },
            ]"
            label="Subject"
            icon="fal fa-folder"
            multiple
          />
        </v-col> -->
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.log_name"
            :choices="[
              { text:'Default', value: 'default' },
              { text:'API', value: 'api' },
              { text:'Fax', value: 'fax' },
              { text:'Interpretations', value: 'interpretations' },
            ]"
            label="Log"
            icon="fal fa-book-bookmark"
            multiple
          />
        </v-col>
      </v-row>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="headers.length - 1">
        <v-row>
          <v-col
            cols="1"
            class="mt-4"
          >
            <v-btn
              small
              icon
              tile
              color="info"
              @click="$clipboard(item.properties)"
            >
              <v-icon small>
                mdi-content-copy mdi-flip-h
              </v-icon>
            </v-btn>
          </v-col>
          <v-col>
            <vue-json-pretty
              :data="item.properties"
              class="my-3"
              style="font-size:0.8em"
            />
          </v-col>
        </v-row>
      </td>
    </template>
    <template #item.log_name="{ item }">
      <v-chip
        small
        outlined
        v-text="item.log_name"
      />
    </template>
    <template #item.subject="{ item }">
      <template v-if="item.subject && (item.subject_type.includes('LabResult') || item.subject_type.includes('Prescription'))">
        <v-icon
          small
          class="mr-1"
        >
          {{ item.subject_type.includes('LabResult') ? 'fal fa-microscope' : 'fal fa-prescription' }}
        </v-icon>
        <router-link :to="{ name: item.subject_type.includes('LabResult') ? 'Lab Results' : 'Prescriptions', query: { uuid: item.subject.uuid } }">
          {{ item.subject.external_id }}
        </router-link>
      </template>
    </template>
    <template #item.causer="{ item }">
      <client-icon
        v-if="item.causer_type && item.causer_type.includes('Client')"
        :key="item.causer.uuid"
        :icon="item.causer.icon"
        :name="item.causer.name"
      />
      <v-tooltip
        v-else-if="item.causer_type && item.causer_type.includes('User')"
      >
        <template>
          <v-avatar
            :key="item.causer.uuid"
            :size="24"
            class="mr-1"
          >
            <v-img :src="item.causer.avatar" />
          </v-avatar>
        </template>
        {{ item.causer.first_name }} {{ item.causer.last_name }}
      </v-tooltip>
    </template>
    <template #item.description="{ item }">
      {{ item.description | truncate(80) }}
    </template>
    <template #item.created_at="{ item }">
      <span class="text-caption">
        {{ item.created_at | moment('L LT') }}
      </span>
    </template>
    <template #footer.prepend>
      <v-select
        v-model="interval"
        dense
        :items="[
          30,60,120
        ]"
        class="v-data-footer__select ml-2 mt-4"
      >
        <template #prepend>
          <span class="text-caption mt-n4">Refresh every</span>
        </template>
        <template #append-outer>
          <span class="text-caption mt-n4">seconds</span>
          <v-progress-circular
            class="ml-2 mt-n4"
            color="grey darken-1"
            :rotate="-90"
            :size="20"
            :width="3"
            :value="100 - (100 * (time_left / (interval -1)))"
          />
        </template>
      </v-select>
      <v-spacer />
    </template>
  </v-data-table>
</template>
<script>
  import 'vue-json-pretty/lib/styles.css';

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      ClientIcon: () => import('@/components/ClientIcon.vue'),
      ClientFilter: () => import('@/components/tables/filters/ClientFilter.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
      VueJsonPretty: () => import('vue-json-pretty'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Logs',
    },
    data () {
      return {
        right: false,
        loading: false,
        options: {
          filter: this.$route.query,
          search: null,
          sortBy: ['id'],
          sortDesc: [true],
        },
        totalItems: null,
        headers: [
          {
            value: 'id',
            text: 'ID',
          },
          {
            value: 'log_name',
            text: 'Log',
          },
          {
            value: 'event',
            text: 'Event',
          },
          {
            value: 'subject',
            text: 'Subject',
          },
          {
            value: 'description',
            text: 'Description',
          },
          {
            value: 'causer',
            text: 'By',
          },
        //   {
        //     value: 'reserved_at',
        //     text: 'Reserved',
        //   },
        //   {
        //     value: 'available_at',
        //     text: 'Available',
        //   },
          {
            value: 'created_at',
            text: 'Date',
          },
        //   {
        //     sortable: false,
        //     align: 'right',
        //     value: 'actions',
        //   },
        ],
        logs: [],
        timer: null,
        time_counter: null,
        interval: 120,
        time_left: this.interval - 1,
      }
    },
    watch: {
      options: {
        deep: true,
        handler () {
          this.fetchLogs()
        },
      },
      interval () {
        this.startTimer()
      },
    },
    mounted() {
      this.startTimer()
    },
    methods: {
      startTimer () {
        clearInterval(this.timer)
        this.timer = setInterval(this.fetchLogs, this.interval * 1000)

        clearInterval(this.time_counter)
        var counter = this.interval - 1
        this.time_counter = setInterval(function() {
          if (counter < 1) {
            counter = this.interval - 1
          }
          this.time_left = counter--
        }.bind(this), 1000)
      },
      fetchLogs () {
        this.loading = true
        const promise = this.axios.get('admin/logs', {
          params: {
            ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
            ...this.$route.query,
          },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchLogs()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.logs = response.data.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.timer)
      next()
    },
  }
</script>
